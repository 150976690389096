import React from 'react';
import { graphql } from 'gatsby';
import Smile from '../assets/images/sase-expert/smiley.svg';
import Layout from "../components/layout";
import {LANG} from "../common/consts"
import '../assets/styles/pages/expert-thank-you.scss';

const ExpertThankYou = (props) => {
    const {data} = props;
    const {content, pageSeoTitle, expertThankYou} = data.wpPage;

    return (
        <Layout
            className={'expert-thank-you-page'}
            yoastData={data.wpPage.seo}
            whiteMenu={false}
            seoTitle={pageSeoTitle.pageSeoTitle}
            title={data.wpPage.title}
            lang={props.pageContext.lang}
            translations={props.pageContext.translations}
            wpid={data.wpPage.databaseId}

        >
            <section className="content">
                <div className="container">
                    <h1>{expertThankYou.expertThankYouHeadline ? expertThankYou.expertThankYouHeadline : 'Thank You'}</h1>
                    <div dangerouslySetInnerHTML={{__html: content}}/>
                    <img src={Smile} alt=""/>
                </div>
                <div style={{width: '100%'}}>
                    <img src="https://trackingapi.trendemon.com/api/Events/pixel?AccountId=1644&GoalId=697" alt="" />
                    <div className="trd-ph-embedded" data-group="recommend"></div>
                </div>
            </section>
        </Layout>
    )
};

export default ExpertThankYou;

export const pageQuery = graphql`
    query expertThankYouPageQuery($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
            id
            databaseId
            path: uri
            ...YoastData
            pageSeoTitle {
                pageSeoTitle
            }
            expertThankYou {
                expertThankYouHeadline
            }
        }
    }
`